import './App.css';

function App() {
  return (
    <div className="App">
      <section className="green1">
        <h1>Merry Christmas Mom &amp; Dad!</h1>
      </section>
      <section className="red">
        <h1>Kim and I appreciate you giving us nights away when you babysit Daxton...</h1>
      </section>
      <section className="green2">
        <h1>So we'd love to give you a night out!</h1>
      </section>
      <section className="orange">
        <h2>Enjoy dinner at <a href="https://www.rpmrestaurants.com/rpm-italian-chicago/">RPM Italian</a></h2>
        <a href="https://www.rpmrestaurants.com/rpm-italian-chicago/">
          <img src={`${process.env.PUBLIC_URL}/rpm-italian.jpg`} />
        </a>
      </section>
      <section className="yellow">
        <h2>Then enjoy a night at the <a href="https://www.pendry.com/chicago/">Pendry</a> hotel</h2>
        <a href="https://www.pendry.com/chicago/">
          <img src={`${process.env.PUBLIC_URL}/pendry-tower-bedroom-suite.jpg`} />
        </a>
      </section>
      <section className="green1">
        <h1>But wait...</h1>
      </section>
      <section className="red">
        <h1>We couldn't decide if you wanted a night out in the city or somewhere else, so why not both?</h1>
      </section>
      <section className="orange">
        <h2>Enjoy dinner at <a href="https://www.nichegeneva.com/">Niche</a> in Geneva</h2>
        <a href="https://www.nichegeneva.com/">
          <img src={`${process.env.PUBLIC_URL}/niche-geneva.jpg`} />
        </a>
      </section>
      <section className="yellow">
        <h2>Then enjoy a night in the Herrington Suite at the <a href="https://www.herringtoninn.com/">Herrington Inn</a></h2>
        <a href="https://www.herringtoninn.com/">
          <img src={`${process.env.PUBLIC_URL}/herrington-suite.jpg`} />
        </a>
      </section>
      <section className="red">
        <h1>Schedule at your leisure, each gift card is good for a year... Expect an email shortly.</h1>
      </section>
      <section className="green1">
        <h1>Merry Christmas!</h1>
      </section>
      <section className="orange">
        <h1>Love, Michael & Kim</h1>
      </section>
    </div>
  );
}

export default App;
